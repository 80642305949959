import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HomePage from "../pages/HomePage";
import Service from "../pages/Service";
import PageNotFound from "../pages/PageNotFound";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import ScrollUp from "./ScrollUp";
import { Stack } from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { WhatsApp } from "@mui/icons-material";

import LOGO from "../assets/images/rlogo.png";

const drawerWidth = 240;
const navItems = [];

function MainComponent(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Rewinity Labs
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <Box
            onClick={() => navigate("/")}
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              justifyContent: "flex-start",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={LOGO} style={{ width: "70px", height: "auto" }} />{" "}
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Rewinity Labs
            </Typography>
          </Box>

          <Stack direction={{ xs: "row", sm: "row" }} spacing={1}>
            <Typography
              sx={{
                border: "1px solid white",
                borderRadius: "10px",
                textAlign: "center",
                height: "35px",
                width: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              target="_blank"
              href={`mailto:${"contact@rewinitylabs.com"}?subject="Interested"&body=Hii`}
              component={"a"}
            >
              <EmailRoundedIcon fontSize="small" color="secondary" />
            </Typography>
            <Typography
              sx={{
                border: "1px solid white",
                borderRadius: "10px",
                textAlign: "center",
                height: "35px",
                width: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${919944603844}&text=Hi&app_absent=0`}
              component={"a"}
            >
              <WhatsApp color="secondary" fontSize="small" />
            </Typography>
          </Stack>
          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff" }}>
                {item}
              </Button>
            ))}
          </Box> */}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<Service />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Box>
      <ScrollUp />
    </Box>
  );
}

export default MainComponent;
