import { Button, Grid, Typography, styled } from "@mui/material";
import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { useNavigate } from "react-router-dom";
//

const Title = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "24px",
}));

const Content = styled(Typography)(() => ({
  fontSize: "16px",
  color: "gray",
  marginBottom: "20px",
}));

export default function Feature({
  id = "",
  left = false,
  image = "",
  title = "",
  content = "",
  path = "",
}) {
  const navigate = useNavigate();
  const handleSeeMoreClick = () => {
    navigate("/services", { state: { serviceId: id } });
  };
  return (
    <Grid
      sx={{
        // marginBottom: "30px",
        padding: "20px",
        borderLeft: !left ? "2px solid #FFCC00" : "none",
        borderRight: !left ? "none" : "2px solid #FFCC00",
        borderBottomLeftRadius: !left ? "25px" : "none",
        borderTopLeftRadius: !left ? "25px" : "none",
        borderBottomRightRadius: left ? "25px" : "none",
        borderTopRightRadius: left ? "25px" : "none",
        borderBottom: "1px solid #FFCC00",
        borderTop: "1px solid #FFCC00",
        clipPath: left
          ? "polygon(9% 0, 100% 0, 100% 20%, 100% 100%, 92% 100%, 9% 100%, 0% 92%, 0% 20%)"
          : "polygon(0 0, 92% 0, 100% 16%, 100% 86%, 92% 100%, 0 100%, 0% 80%, 0% 20%)",
      }}
      container
    >
      <Grid item xs={12} sm={4} order={{ xs: 1, sm: left ? 1 : 2 }}>
        <img
          src={image}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            objectFit: "contain",
          }}
        />
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 2,
        }}
        item
        xs={12}
        sm={8}
        order={{ xs: 2, sm: !left ? 1 : 2 }}
      >
        <Title>{title}</Title>
        <Content>{content}</Content>
        <Button
          onClick={handleSeeMoreClick}
          size="small"
          variant="outlined"
          endIcon={<ArrowRightAltRoundedIcon fontSize="small" />}
          sx={{ alignSelf: { xs: "flex-end", sm: "flex-start" } }}
        >
          See More
        </Button>
      </Grid>
    </Grid>
  );
}
