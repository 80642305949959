import WebDevelopment from "../assets/images/webDevelopment.png";
import AppDevelopment from "../assets/images/appDevelopment.png";
import UIDesign from "../assets/images/uiDesign.png";
import DataAnalysis from "../assets/images/dataAnalysis.png";

import Predesign from "../assets/images/predesign.png";
import AurBtao from "../assets/images/aurbtao.png";
import CashYog from "../assets/images/cashyog.png";

import Design1 from "../assets/images/design1.jpg";
import Design2 from "../assets/images/design2.jpg";
import Design3 from "../assets/images/design3.jpg";
import Design4 from "../assets/images/design4.jpg";

import Mobile1 from "../assets/images/mobile1.png";
import Mobile2 from "../assets/images/mobile2.png";
import Mobile3 from "../assets/images/mobile3.png";

import Audio from "../assets/images/audio.png";

import Data1 from "../assets/images/data1.png";
import Data2 from "../assets/images/data2.png";

import audio1 from "../assets/audio/audio1.mpeg";
import audio2 from "../assets/audio/audio2.mpeg";

const SERVICE_DATA = [
  {
    id: "webDevelopment",
    title: "Web Development",
    image: WebDevelopment,
    bulletPoints: [
      {
        label: "Consultation & Discovery",
        description:
          "We start by understanding your requirements, goals, and target audience",
      },
      {
        label: "Planning & Strategy",
        description:
          "Crafting a comprehensive plan outlining project milestones, technologies, and resources",
      },
      {
        label: "Design & Development",
        description:
          "Our expert team designs wireframes, UI elements, and codes the backend and frontend",
      },
      {
        label: "Testing & Quality Assurance",
        description:
          "Rigorous testing to ensure functionality, performance, and compatibility across devices",
      },
      {
        label: "Deployment & Launch",
        description:
          "Smooth deployment to your hosting environment and launch to the public",
      },
      {
        label: "Maintenance & Support",
        description:
          "Continuous monitoring, updates, and support to keep your web app running smoothly",
      },
    ],
    projects: [
      {
        label: "PreDesign",
        description:
          "Predesign is a solution developed by us to generate usefull information needed before proceeding to actual design process.",
        image: Predesign,
        link: "https://predesign.in/",
        showLink: true,
      },
      {
        label: "AurBtao",
        description:
          "An AI powered Avatars creation platform where people could create avatars based on their personality and also interact with others.",
        image: AurBtao,
        link: "",
        showLink: true,
      },
      {
        label: "CashYog",
        description:
          "An awesome e-commerce platform for a wide range of services and cashbacks upto 70%",
        image: CashYog,
        link: "",
        showLink: true,
      },
    ],
  },
  {
    id: "mobileAppDevelopment",
    title: "Mobile Development",
    image: AppDevelopment,
    bulletPoints: [
      {
        label: "Requirement Analysis",
        description:
          "Understanding your app's purpose, features, and target platform",
      },
      {
        label: "Prototyping & Design",
        description:
          "Creating wireframes and UI/UX designs for user-friendly interfaces",
      },
      {
        label: "Development & Integration",
        description:
          "Building the app's backend, frontend, and integrating necessary APIs",
      },
      {
        label: "Testing & Refinement",
        description:
          "Thorough testing to identify and fix bugs, ensuring a seamless user experience",
      },
      {
        label: "Deployment & Launch",
        description:
          "Uploading the app to respective app stores and facilitating its launch",
      },
      {
        label: "Post-launch Support",
        description:
          "Providing ongoing maintenance, updates, and support to address any issues and keep the app optimized",
      },
    ],
    projects: [
      {
        label: "Coffee Daily",
        description: "",
        image: Mobile1,
        link: "",
        showLink: false,
      },
      {
        label: "A platform to sell Mobile phones",
        description: "",
        image: Mobile2,
        link: "",
        showLink: false,
      },
    ],
  },
  {
    id: "uiDesign",
    title: "UI Design",
    image: UIDesign,
    bulletPoints: [
      {
        label: "Research & Analysis",
        description:
          "Understanding your brand identity, target audience, and design preferences",
      },
      {
        label: "Sketching & Wireframing",
        description:
          "Creating rough sketches and wireframes to visualize the layout and flow",
      },
      {
        label: "Mockup & Prototyping",
        description:
          "Designing high-fidelity mockups and interactive prototypes for feedback and refinement",
      },
      {
        label: "UI Development",
        description:
          "Translating approved designs into pixel-perfect UI elements and style guides",
      },
      {
        label: "User Testing",
        description:
          "Conducting usability tests to gather feedback and make necessary adjustments",
      },
      {
        label: "Finalization & Delivery",
        description:
          "Delivering the finalized UI assets and providing support for implementation and integration",
      },
    ],
    projects: [
      {
        label: "Fashion Blog",
        description: "",
        image: Design1,
        link: "",
      },
      {
        label: "Hair Styling",
        description: "",
        image: Design2,
        link: "",
      },
      {
        label: "Clothing and Accessories",
        description: "",
        image: Design3,
        link: "",
      },
      {
        label: "Landing page for Startup",
        description: "",
        image: Design4,
        link: "",
      },
    ],
  },
  {
    id: "dataAnalysis",
    title: "Data Analysis",
    image: DataAnalysis,
    bulletPoints: [
      {
        label: "Requirement Gathering",
        description:
          "Understanding your business objectives, data sources, and analysis goals",
      },
      {
        label: "Data Collection & Preparation",
        description:
          "Gathering relevant data from various sources and preprocessing for analysis",
      },
      {
        label: "Analysis & Modeling",
        description:
          "Applying statistical techniques, machine learning algorithms, or other methods to derive insights",
      },
      {
        label: "Interpretation & Visualization",
        description:
          "Presenting findings through reports, dashboards, and interactive visualizations",
      },
      {
        label: "Validation & Iteration:",
        description:
          "Validating insights against business goals and refining analysis based on feedback",
      },
      {
        label: "Presentation & Implementation",
        description:
          "Delivering actionable recommendations and assisting with the implementation of data-driven strategies",
      },
    ],
    projects: [
      {
        label: "Adventure Works - (Dummy Data)",
        description: "",
        image: Data1,
        link: "",
        showLink: false,
      },
      {
        label: "Maven Market - (Dummy Data)",
        description: "",
        image: Data2,
        link: "",
        showLink: false,
      },
    ],
  },
  {
    id: "audioSolution",
    title: "Audio Solution",
    image: Audio,
    bulletPoints: [
      {
        label: "Consultation & Discovery",
        description:
          "We begin by delving into your project's objectives, audience preferences, and desired outcomes. Through collaborative discussions, we gain insights into your vision and tailor our solutions to meet your unique requirements",
      },
      {
        label: "Planning & Strategy",
        description:
          " Crafting a meticulous roadmap that outlines key milestones, technical specifications, and resource allocation. We strategize the implementation of cutting-edge technologies and innovative approaches to ensure the success of your audio solution",
      },
      {
        label: "Design & Development",
        description:
          "Our seasoned team of audio engineers and designers collaborates to create captivating soundscapes and audio elements tailored to your project's needs. From sound effects to music compositions, we meticulously craft each element to evoke the desired emotional response",
      },
      {
        label: "Testing & Quality Assurance",
        description:
          "Rigorous testing procedures are employed to ensure the integrity, consistency, and quality of the audio solution across various platforms and devices. We conduct comprehensive tests to address any potential issues and optimize the user experience",
      },
      {
        label: "Maintenance & Support",
        description:
          "We provide ongoing maintenance, updates, and technical support to ensure the continued performance and relevance of your audio solution. Our dedicated support team is readily available to address any inquiries or issues that may arise, ensuring maximum satisfaction and peace of mind",
      },
    ],
    projects: [
      {
        label: "Project 1",
        description: "",
        // image: Data1,
        audio: audio1,

        link: "",
        showLink: false,
      },
      {
        label: "Project 2",
        description: "",
        // image: Data2,
        audio: audio2,
        link: "",
        showLink: false,
      },
    ],
  },
];

export default SERVICE_DATA;
