import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function CustomSelect({
  value,
  name = "",
  label = "",
  options = [],
  onChange = () => {},
  disabled = false,
  required = false,
}) {
  return (
    <FormControl
      margin="normal"
      required={required}
      variant="outlined"
      size="small"
      fullWidth
      sx={{ top: "4px" }}
    >
      <InputLabel shrink={true} id={label}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        notched
        name={name}
        labelId={label}
        value={value || ""}
        label={label}
        onChange={onChange}
      >
        {options.map((s, i) => (
          <MenuItem key={i} value={s.value}>
            {s.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
