import React, { useRef } from "react";
import {
  Box,
  Typography,
  styled,
  Grid,
  Button,
  Container,
  Stack,
} from "@mui/material";
import * as animationData from "../../assets/Lottie/hero.json";
import Lottie from "react-lottie";
import useSize from "../../hooks/useSize";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  padding: "5px 0",
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "2rem",
  fontWeight: "bold",
  textShadow: "2px 2px 2px rgba(0, 0, 0,0.5)",
}));

const Title = styled(Typography)(() => ({
  color: "white",
  margin: "0 0 20px 0",
  textAlign: "left",
}));

const MainHeader = styled(Typography)(() => ({
  color: "white",
  fontSize: "1.6rem",
  fontWeight: "500",
  lineHeight: "1.9rem",
}));

const GridItemWrapper = styled(Box)(() => ({
  alignItems: "flex-start",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "10px",
}));

const LottieWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ width }) => ({
  maxWidth: width > 584 ? "40vw" : "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
}));

const Hero = ({ style }) => {
  const target = useRef(null);
  let size = useSize(target);

  let width = size ? size.width : 584;
  return (
    <MainContainer sx={style} ref={target}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <GridItemWrapper>
              <LottieWrapper width={width}>
                <Lottie options={defaultOptions} />
              </LottieWrapper>
            </GridItemWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", flex: 1, alignItems: "center" }}
          >
            <GridItemWrapper>
              <MainHeader component="h1" gutterBottom>
                A place where simple yet effective solutions are provided
              </MainHeader>
              <Heading>Rewinity Labs</Heading>
              <Title component="h2" variant="h4" fontSize="1.2rem">
                Let us help you with your problem to find the best and the most
                efficient solution!
              </Title>

              <Stack
                direction={{ xs: "row" }}
                sx={{ marginBottom: "10px" }}
                spacing={1}
              >
                {/* <Button
                  disableElevation
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  Explore Services
                </Button> */}
              </Stack>
            </GridItemWrapper>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default Hero;
