const darkPalette = {
  primary: {
    main: "#421c52",

    // Blue for light mode
  },
  secondary: {
    main: "#FFCC00", // Pink for light mode
  },
  mode: "dark",
};

const lightPalette = {
  primary: {
    main: "#196838", // Light Blue for dark mode
  },
  secondary: {
    main: "#446285", // Light Pink for dark mode
  },
  mode: "light",
};

const shapeProperties = {
  borderRadius: 5,
};

export default {
  lightPalette,
  darkPalette,
  shapeProperties,
};
