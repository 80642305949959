import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import ReactAudioPlayer from "react-audio-player";

export default function ServiceCard({ project }) {
  const handleLinkClick = () => window.open(project.link, "_blank");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        borderRadius: "10px",
        overflow: "hidden",
        padding: "20px",
        boxShadow: (theme) => theme.shadows[3],
        background: "linear-gradient(45deg, #421c52 30%, #FFCC00 90%)",
        flexGrow: 1,
        maxHeight: "405px",
      }}
    >
      {project.image && (
        <img
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            maxWidth: "500px",
            alignSelf: "center",
            marginBottom: "20px",
            maxHeight: "300px",
            background: "whitesmoke",
            borderRadius: "5px",
            overflow: "hidden",
          }}
          src={project.image}
        />
      )}

      {project.audio && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "325px",
            width: "100%",
          }}
        >
          <ReactAudioPlayer src={project.audio} controls />
        </Box>
      )}
      <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "white" }}>
        {project.label}
      </Typography>
      <Typography sx={{ mb: 2, color: "white" }}>
        {project.description}
      </Typography>

      {project.showLink && (
        <Button
          disabled={!project.link}
          disableElevation
          endIcon={<ArrowOutwardRoundedIcon fontSize="small" />}
          size="small"
          variant="contained"
          onClick={handleLinkClick}
          color="secondary"
        >
          Visit
        </Button>
      )}
    </Box>
  );
}
