import { createTheme, ThemeProvider } from "@mui/material";
import { useState } from "react";
import ThemeModeContext from "./context/ThemeModeContext";

// data
import themeData from "./data/themeData";
import MainComponent from "./components/MainComponent";
import { ToastContainer } from "react-toastify";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#421c52",
      },
      secondary: {
        main: "#FFCC00",
      },
    },
    shape: {
      borderRadius: themeData.shapeProperties.borderRadius,
    },
    typography: {
      allVariants: {
        // color: isDarkMode ? "white" : "black",
        fontSize: "14px",
      },
    },

    components: {
      MuiTextField: {
        styleOverrides: {
          root: {},
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              // backgroundColor: isDarkMode ? "#4f4f4f" : "#e8eaed",
            },
          },
        },
      },
      MuiIcon: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#fff",
          },
          root: {
            padding: "8px",
            height: "40px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
          },
        },
      },

      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: 0,
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
        <ThemeProvider theme={theme}>
          <MainComponent />
        </ThemeProvider>
        <ToastContainer />
      </ThemeModeContext.Provider>
    </>
  );
}

export default App;
