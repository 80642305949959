import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { WhatsApp } from "@mui/icons-material";

const AppLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.light,
  cursor: "pointer",
  display: "block",
  fontSize: "0.8rem",
  textAlign: "center",
  textDecoration: "none",
  ":hover": {
    color: theme.palette.secondary.dark,
  },
}));

const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "right",
})(() => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const MainContainer = styled(Box)(({ theme }) => ({
  padding: "40px 20px 20px 20px",
  backgroundColor: "black",
  [theme.breakpoints.down(280)]: {
    padding: "80px 10px 20px 10px",
  },
}));

const CopyRightText = styled(Typography)(() => ({
  color: "white",
  textAlign: "center",
  // marginTop: "20px",
  fontSize: "0.8rem",
}));

const SocialLinkWrapper = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  justifyContent: "center",
  marginBottom: "40px",
}));

const SocialLinksContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  justifyContent: "space-around",
  padding: "0 0 0 10px",
  marginBottom: "5px",
}));

const SocialLink = styled(Link)(({ theme }) => ({
  border: "1px solid lightgray",
  borderRadius: theme.shape.borderRadius,
  color: "black",
  fontSize: "14px",
  padding: "5px",
  textDecoration: "none",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  boxShadow: theme.shadows[1],
}));

function Footer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    // let phoneNumber = "919263478220";
    let phoneNumber = "919944603844";

    let url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hi&app_absent=0`;
    window.open(url);
  };

  const handleEmailClick = (e) => {
    e.preventDefault();
    window.open(
      `mailto:${"contact@rewinitylabs.com"}?subject="Interested"&body=Hii`
    );
  };

  if (!["/", "/services"].includes(window.location.pathname)) return null;

  return (
    <footer>
      <MainContainer>
        <Box>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <ItemWrapper>
                <Typography
                  sx={{ color: "white", textAlign: "center" }}
                  gutterBottom
                >
                  Help
                </Typography>
                <AppLink
                  onClick={() =>
                    navigate("/privacy_policy", {
                      replace: pathname === "/privacy_policy" ? true : false,
                    })
                  }
                  gutterBottom
                  cursor="pointer"
                >
                  Privacy Policy
                </AppLink>
                <AppLink
                  gutterBottom
                  onClick={() =>
                    navigate("/terms_of_service", {
                      replace: pathname === "/terms_of_service" ? true : false,
                    })
                  }
                >
                  Terms of Use
                </AppLink>
                <AppLink
                  gutterBottom
                  onClick={() =>
                    navigate("/about_us", {
                      replace: pathname === "/about_us" ? true : false,
                    })
                  }
                >
                  About Us
                </AppLink>
              </ItemWrapper>
            </Grid> */}

            {/* <Grid item xs={12} md={12}>
              <ItemWrapper right={true}>
                <Typography
                  gutterBottom
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "18px",
                    mb: 2,
                  }}
                >
                  Connect
                </Typography>
                <SocialLinkWrapper>
                  <Stack direction={{ xs: "row", sm: "row" }} spacing={1}>
                    <SocialLink
                      target={"_blank"}
                      href="https://www.linkedin.com/company/cashyog/"
                    >
                      <LinkedInIcon fontSize="small" color="secondary" />
                    </SocialLink>
                    <SocialLink
                      target={"_blank"}
                      href="https://www.instagram.com/cashyog_100"
                    >
                      <InstagramIcon fontSize="small" color="secondary" />
                    </SocialLink>
                    <IconButton
                      sx={{
                        border: "1px solid white",
                        borderRadius: "5px",
                      }}
                      onClick={handleEmailClick}
                      size="small"
                    >
                      <EmailRoundedIcon fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      sx={{
                        border: "1px solid white",
                        borderRadius: "5px",
                      }}
                      onClick={handleWhatsAppClick}
                      size="small"
                    >
                      <WhatsApp color="secondary" fontSize="small" />
                    </IconButton>
                  </Stack>
                </SocialLinkWrapper>
              </ItemWrapper>
            </Grid> */}
          </Grid>
        </Box>

        <CopyRightText>
          Rewinity Labs &#169; {new Date().getFullYear()} All rights reserved.
        </CopyRightText>
      </MainContainer>
    </footer>
  );
}

export default Footer;
