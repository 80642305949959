import WebDevelopment from "../assets/images/webDevelopment.png";
import AppDevelopment from "../assets/images/appDevelopment.png";
import UIDesign from "../assets/images/uiDesign.png";
import DataAnalysis from "../assets/images/dataAnalysis.png";
import Audio from "../assets/images/audio.png";

const FEATURES = [
  {
    id: "webDevelopment",
    title: "Web Development",
    content:
      "Our web development wizards craft digital experiences that resonate. From sleek e-commerce platforms to robust enterprise solutions, we fuse creativity with functionality to elevate your online presence. Seamlessly blending cutting-edge technologies with intuitive design, we ensure your web app captivates users and drives results. Let's transform your vision into a dynamic reality that leaves a lasting impression.",
    image: WebDevelopment,
    path: "",
    left: false,
  },
  {
    id: "mobileAppDevelopment",
    title: "Mobile App Development",
    content:
      "Ignite engagement and empower your audience with our bespoke mobile solutions. Harnessing the power of innovation, we sculpt feature-rich apps tailored to your unique needs. Whether iOS or Android, we engineer seamless experiences that redefine user interaction. From concept to launch, we guide you through every step of the journey, delivering apps that inspire and endure.",
    image: AppDevelopment,
    path: "",
    left: true,
  },
  {
    id: "uiDesign",
    title: "UI Designs",
    content:
      "Elevate your brand with captivating UI designs that spark connection. Our design aficionados blend aesthetics with usability to craft immersive interfaces that captivate audiences. From wireframes to pixel-perfect prototypes, we infuse every detail with intentionality, ensuring your brand's essence shines through. Let's craft an experience that resonates deeply and leaves a lasting impression on every user.",
    image: UIDesign,
    path: "",
    left: false,
  },
  {
    id: "dataAnalysis",
    title: "Data Analysis",
    content:
      "Uncover actionable insights and unlock the true potential of your data. Our analytical maestros dive deep into the numbers, unraveling patterns and trends that drive informed decision-making. From predictive modeling to data visualization, we transform raw data into strategic assets that fuel growth. Harness the power of data to gain a competitive edge and steer your business towards success.",
    image: DataAnalysis,
    path: "",
    left: true,
  },
  {
    id: "audioSolution",
    title: "Audio Solution",
    content:
      "Embark on a sonic journey like no other with our comprehensive audio solutions. We harmonize innovation with creativity to craft immersive soundscapes that elevate your projects to new heights. From custom sound design to audio branding, we cater to diverse needs with precision and flair. Our team of seasoned professionals utilizes state-of-the-art technology and artistic finesse to deliver audio experiences that resonate with your audience. Whether it's enhancing user experiences, creating impactful advertisements, or crafting memorable soundtracks, we're your trusted partner in achieving sonic excellence. Let's transform your vision into an unforgettable auditory adventure that captivates and inspires",
    image: Audio,
    path: "",
    left: false,
  },
];

export default FEATURES;
