import React from "react";
import Hero from "../components/HomePage/Hero";
import Feature from "../components/HomePage/Feature";
import { Container, Typography, styled } from "@mui/material";
import FEATURES from "../data/featureData";
import ScrollUp from "../components/ScrollUp";

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "30px",
  alignSelf: "center",
  textAlign: "center",
}));

export default function HomePage() {
  return (
    <>
      <Hero />
      <Container component="section" sx={{ my: 5 }}>
        <SectionTitle>Services</SectionTitle>

        {FEATURES.map((f) => (
          <Feature
            id={f.id}
            key={f.id}
            left={f.left}
            title={f.title}
            content={f.content}
            image={f.image}
            path={f.path}
          />
        ))}
      </Container>
      <ScrollUp />
    </>
  );
}
