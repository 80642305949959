import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SERVICE_DATA from "../data/serviceData";
import { Box, Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import CustomSelect from "../components/CustomSelect";
import ServiceCard from "../components/ServiceCard";
import Request from "../components/Request";
import ServiceSteps from "../components/ServiceSteps";

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "20px",
  alignSelf: "center",
  textAlign: "center",
  marginTop: "50px",
  padding: "10px 15px",
  borderRadius: "50px",
  marginBottom: "30px",
}));

const SelectedService = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "14px",
  alignSelf: "center",
  borderRadius: "5px",
  padding: "5px 10px",
  marginRight: "10px",
  width: "250px",
  marginTop: "20px",
  textAlign: "center",
}));

const BulletsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "22px",
  textAlign: "left",
  marginBottom: "15px",
  textDecoration: "underline",
  padding: "10px",
}));

const PointWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  columnGap: "5px",
  alignItems: "center",
  marginBottom: "10px",
}));

const PointName = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "bold",
  background: "whitesmoke",
  padding: "5px 10px",
  borderRadius: "20px",
}));

const PointDescription = styled(Typography)(() => ({
  fontSize: "16px",
  color: "gray",
}));

export default function Service() {
  const { state } = useLocation();
  const [data, setData] = useState(
    state?.serviceId
      ? SERVICE_DATA.find((s) => s.id == state?.serviceId)
      : SERVICE_DATA[0]
  );

  const handleServiceChange = (e) => {
    setData(SERVICE_DATA.find((s) => s.id == e.target.value));
  };

  return (
    <Container
      maxWidth={"xl"}
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          maxWidth: "400px",
          alignSelf: { xs: "center", sm: "flex-end" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexWrap: { xs: "wrap", sm: "nowrap" },
          marginBottom: "50px",
        }}
      >
        <SelectedService>{data.title}</SelectedService>
        <CustomSelect
          label="Select Service"
          value={data.id}
          onChange={handleServiceChange}
          options={SERVICE_DATA.map((s) => ({ label: s.title, value: s.id }))}
        />
      </Box>
      <Grid spacing={2} container>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          md={6}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "60% 40% 40% 20% / 70% 50% 30% 25%",
              boxShadow: (theme) => theme.shadows[3],
              overflow: "hidden",
              padding: 2,
              width: { xs: "100%", sm: "90%" },
            }}
          >
            <img
              src={data.image}
              style={{
                height: "auto",
                width: "90%",
                objectFit: "contain",
                maxWidth: "400px",
                // backgroundColor: "whitesmoke",
              }}
            />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          item
          xs={12}
          md={6}
        >
          <BulletsTitle>
            After receiving a request for this service, our standard procedure
            entails...
          </BulletsTitle>

          <>
            <ServiceSteps steps={data.bulletPoints} />
            {/* {data.bulletPoints.map((b, i) => (
              <PointWrapper key={b.label + i.toString()}>
                <PointName>
                  {b.label} :{" "}
                  <PointDescription component="span">
                    {b.description}
                  </PointDescription>{" "}
                </PointName>
              </PointWrapper>
            ))} */}
          </>
        </Grid>
      </Grid>

      <SectionTitle sx={{ textDecoration: "underline" }}>
        Some of the projects done by us
      </SectionTitle>

      <Grid sx={{ mb: 5 }} spacing={2} container>
        {data.projects.map((p, i) => (
          <Grid
            key={p.link + i.toString() + SERVICE_DATA.indexOf(data).toString()}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: "flex" }}
          >
            <ServiceCard project={p} />
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4}>
          <Request selectedService={data.id} />
        </Grid>
      </Grid>
    </Container>
  );
}
