import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { post } from "../services/apiMethods";
import { Box, Typography, styled } from "@mui/material";
import FormSelect from "../Form/FormSelect";
import SERVICE_DATA from "../data/serviceData";
import FormInput from "../Form/FormInput";
import { LoadingButton } from "@mui/lab";

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "24px",
  alignSelf: "center",
  textAlign: "center",
  //   padding: "10px 15px",
  borderRadius: "50px",
}));

export default function Request({ selectedService = "" }) {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const { data } = await post("/request", values);
      formik.resetForm();
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      contactNumber: "",
      contactEmail: "",
      requirement: "",
      service: selectedService || "",
    },
    validationSchema: Yup.object({
      contactNumber: Yup.string().max(10).min(10).label("Contact Number"),
      contactEmail: Yup.string().email().required().label("Contact Email"),
      requirement: Yup.string().max(1000).min(10).required("Requirement"),
      service: Yup.string().required().label("Service"),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "600px",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "5px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: (theme) => theme.shadows[3],
        marginBottom: "30px",
        // background: "linear-gradient(45deg, #421c52 30%, #FFCC00 90%)",
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <SectionTitle>This can be yours</SectionTitle>

      <FormSelect
        name="service"
        label="Select Service"
        formik={formik}
        required={true}
        options={SERVICE_DATA.map((s) => ({ label: s.title, value: s.id }))}
      />
      <FormInput
        name="requirement"
        label="Requirement"
        formik={formik}
        required={true}
        multiline={true}
        maxHeight="200px"
      />
      <FormInput
        name="contactEmail"
        label="Email"
        formik={formik}
        required={true}
      />
      <FormInput name="contactNumber" label="Contact Number" formik={formik} />

      <LoadingButton
        sx={{ marginTop: "20px" }}
        loading={submitting}
        type="submit"
        size="small"
        variant="contained"
        fullWidth
      >
        Submit Request
      </LoadingButton>
    </Box>
  );
}
